@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

.homeContainer {
  font-family: 'Roboto', sans-serif;
  background-color: #125c68; /* Deep teal background */
  color: #eec4b4; /* Soft peach text color */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh; 
}

.homeWrap {
  text-align: center;
  padding: 20px;
  background: #ffffff; 
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #125c68; 
  width: 90%; 
  max-width: 600px; /* Limit maximum width for large screens */
}

.logoContainer {
  text-align: center;
  padding: 20px;
  margin-bottom: 20px; /* Space below the logo */
}

.logo {
  width: 300px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center the logo horizontally */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.logo:hover {
  transform: scale(1.05); 
}

.homeContainer .title {
  font-family: 'Lobster', cursive;
  font-size: 2.5em; /* Adjust font size for large screens */
  margin-bottom: 15px;
}

.homeContainer .description {
  font-size: 1.2em; /* Adjust font size for large screens */
  margin-bottom: 30px;
}

.imagesContainer {
  display: flex;
  justify-content: space-between; /* Even spacing between items */
  gap: 10px; /* Space between the property wraps */
  align-items: center; /* Center alignment */
  margin: 0 auto; /* Center container */
  padding: 10px 0; /* Add vertical padding around the property wraps */

}

.propertyWrap {
  position: relative;
  width: 300px;
  height: 200px;
  transition: transform 0.2s;
  border: 2px solid #eec4b4;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.propertyImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.2s;
}

.propertyWrap:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.propertyName {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 10px 0;
  font-size: 1em;
  text-align: center;
}

.propertyWrap:hover .propertyName {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 1280px) {
  .homeContainer {
    height: auto;
    padding: 5px 10px; /* Minimized padding to reduce empty space */
    margin: 0 auto; /* Center the container if needed */
  }

  .homeWrap {
    width: 100%; /* Utilize full width of the parent container */
    padding: 5px; /* Reduced padding for compactness */
    margin: 0; /* Remove extra margins */
  }

  .logo {
    width: 50%; /* Smaller logo for compact layout */
    max-width: 150px; /* Further reduce max-width */
  }

  .homeContainer .title {
    font-size: 1.8em; /* Smaller font size for title */
    margin-bottom: 10px; /* Reduce spacing below title */
  }

  .homeContainer .description {
    font-size: 1.2em; /* Smaller description text */
    margin-bottom: 10px; /* Reduce spacing below description */
  }

  .imagesContainer {
    display: flex;
    justify-content: space-between; /* Ensure even spacing without excessive gaps */
    gap: 10px; /* Small consistent gap between items */
    align-items: center; /* Vertically align items in the container */
    margin: 0 auto; /* Center the images container horizontally */
    padding: 20px 0; /* Increase padding slightly for smaller screens */

  }

  .propertyWrap {
    width: 90%; /* Slightly smaller width for smaller screens */
    max-width: 300px; /* Constrain the maximum width */
    margin: 0; /* Remove additional margins to control spacing */
    transition: transform 0.2s;
  }

  .propertyImage {
    width: 100%; /* Make image fill the width of the wrapper */
    height: 100%; /* Make image fill the height of the wrapper */
    object-fit: cover; /* Ensure the image maintains its aspect ratio and covers the wrap */
  }
}
