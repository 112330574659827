@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

/* Outer container to apply the same deep teal background */
.policyContainerOuter {
  background-color: #125c68; /* Deep teal background matching homeContainer */
  color: #eec4b4; /* Soft peach text color */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

/* Inner container for the actual privacy policy content */
.policyContainer {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #125c68;
  padding: 20px;
  max-width: 800px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.policyTitle {
  font-family: 'Lobster', cursive;
  font-size: 2.5em;
  color: #125c68;
  margin-bottom: 15px;
}

.policySubtitle {
  font-size: 1.5em;
  color: #125c68;
  margin-top: 20px;
  margin-bottom: 10px;
}

.policyParagraph {
  font-size: 1em;
  line-height: 1.6;
  color: #333;
  margin-bottom: 15px;
}
