.amica-rose-container {
  background-color: #006477; 
  font-family: 'Roboto', sans-serif;
}

/* Navbar styles */
.amica-rose-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 40px;
  margin: 0 20px;
  position: relative;
}

.amica-rose-logo {
  height: 100px;
  width: auto;
}

.amica-rose-nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  align-items: center; 
  margin-left: auto;
  margin-right: 20px;
}

.amica-rose-nav-links li {
  display: inline;
}

.amica-rose-nav-links a {
  color: #eec4b4;
  text-decoration: none;
  position: relative;
  font-size: 20px;
}

.amica-rose-nav-links a:hover::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #eec4b4;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.amica-rose-book-now {
  background-color: #eec4b4;
  color: #006477;
  border: none;
  padding: 12px 24px; 
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow effect */
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transition */
}

.amica-rose-book-now:hover {
  background-color: #d9b3a6;
  transform: translateY(-2px); /* Lifts the button slightly on hover */
}

@media (max-width: 768px) {
  .amica-rose-logo {
      height: 60px;
      padding: 5px;
  }

  .amica-rose-menu-toggle {
      display: block;
      cursor: pointer;
  }

  .amica-rose-book-now {
      font-size: 14px;
      padding: 8px 16px;
  }

  .amica-rose-nav-links {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 50%;
      transform: translateX(-57%);
      width: 40vw;
      background-color: #333;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      z-index: 1001;
  }

  .amica-rose-nav-links.active {
      display: flex;
  }

  .amica-rose-nav-links li {
      text-align: center;
      padding: 5px 0;
  }

  .amica-rose-nav-links a:hover {
      background-color: #555;
  }
}
