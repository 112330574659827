/* Remove the global body styles */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.amica-rose-container {
    background-color: #006477; 
    font-family: 'Roboto', sans-serif;
}

/* Navbar styles */
.amica-rose-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px; /* Increased padding horizontally */
    margin: 0 20px; /* Left and right margin */
    /* background-color: white;  */
    /* z-index: 1000; */
    position: relative;
  }

.amica-rose-logo {
  height: 100px;
  /* margin-left: 60%; */
  padding: 5px;
  width: auto;
}

.amica-rose-nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  align-items: center; 
  margin-left: auto; /* Push links to the right */
  margin-right: 20px; 
}

.amica-rose-nav-links li {
  display: inline;
}

.amica-rose-nav-links a {
  color: #eec4b4;
  text-decoration: none;
  /* font-weight: 8000; */
  position: relative;
  font-size: 20px;
}

.amica-rose-nav-links a:hover::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #eec4b4; /* Color of the underline */
    position: absolute;
    bottom: -2px; /* Position the underline below the text */
    left: 0;
  }
  

/* Main image section styles */
.amica-rose-main-image-wrap {
    position: relative;
    text-align: center;
    margin: 10px 10px 20px 10px; /* Adjusted margin */
    border-radius: 15px;
    overflow: hidden;
    max-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .amica-rose-main-image {
    max-width: 100%;
    max-height: 50%;
    width: auto;
    height: auto;
    cursor: pointer;
    border-radius: 15px;
  }
  
  .lodgify-search-bar-container {
    position: absolute; /* Position the Lodgify bar */
    top: 480px;
    right: 25%;
    width: 100%;
    z-index: 100; /* Ensure it's above other content */
  }
  
  .amica-rose-view-more {
    position: absolute;
    bottom: 50px;
    right: 100px;
    background-color: #eec4b4;
    color: #006477;
    border: none;
    padding: 12px 24px;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added shadow effect */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transition */
  }
  
  .amica-rose-view-more:hover {
    background-color: #d9b3a6;
    transform: translateY(-2px); /* Lifts the button slightly on hover */
  }
  
  .amica-rose-view-more-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px; /* Spacing between icon and text */
  }

.amica-rose-carousel {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    z-index: 999;
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
  }
  
  .amica-rose-carousel-content {
    /* position: relative; */
    display: flex;
    align-items: center;
  }
  
  .amica-rose-carousel img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
  
  .amica-rose-close-carousel {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    cursor: pointer;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 4em;
    cursor: pointer;
  }
  
  .carousel-control.prev {
    left: 10px;
  }
  
  .carousel-control.next {
    right: 20px;
  }
  
  /* down bar */
  .amica-rose-down-bar {
    display: flex;
    justify-content: center;
    gap: 40px; /* Increased space between the links */
    margin: 20px 0;
    color: #eec4b4; /* Same color as the links in the top nav bar */
    font-size: 20px;
    position: relative;
  }
  
  .amica-rose-down-bar a {
    color: #eec4b4;
    text-decoration: none;
    font-weight: 700;
    position: relative;
    padding: 10px 0; /* Additional padding for better spacing */
  }
  
  .amica-rose-down-bar a:hover::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #eec4b4; /* Color of the underline */
    position: absolute;
    bottom: -2px; /* Position the underline below the text */
    left: 0;
  }
  
  /* Horizontal line */
  .amica-rose-down-bar::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #eec4b4;
    position: absolute;
    bottom: 0;
    left: 0;
  }

/* Styles for the amica-rose-fields section */
.amica-rose-fields {
    background-color: white;
    font-family: 'Roboto', sans-serif;
    padding: 40px; /* Added padding for inner content spacing */
    margin: 20px auto; /* Centered and added vertical margin */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    max-width: 800px; /* Set a max width for the content */
  }
  
  .amica-rose-section {
    margin-bottom: 40px; /* Space between sections */
    padding: 20px;
  }
  
  .amica-rose-section h2 {
    color: #006477; /* Consistent color with theme */
    font-size: 28px; /* Larger font size for section titles */
    border-bottom: 2px solid #eec4b4; /* Underline effect */
    padding-bottom: 10px; /* Space between title and content */
    margin-bottom: 20px; /* Space after the title */
  }
  
  .amica-rose-section p {
    color: #333; /* Darker color for better readability */
    line-height: 1.6; /* Increased line height for readability */
    font-size: 18px; /* Slightly larger font size */
    margin: 0; /* Reset margin */
    padding: 10px 0; /* Padding for spacing */
  }
  
  .amica-rose-amenities {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Adjust the gap between columns */
  }
  
  .amica-rose-amenity {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .amica-rose-amenity .icon {
    font-size: 20px;
    margin-right: 10px;
  }
  
  .amica-rose-amenity span {
    font-size: 16px;
  }
  

/* If necessary, adjust the styling for the icon */
.amica-rose-menu-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
  fill: #eec4b4; /* Ensure the color matches your theme */
}
/* General styles for non-mobile screens */
.amica-rose-menu-toggle {
  display: none; /* Hide menu toggle by default */
}

@media (max-width: 768px) {
  .amica-rose-logo {
      height: 60px; /* Make the logo smaller */
      padding: 5px;
  }
/* Add this section */
.amica-rose-menu-toggle {
  display: block; /* Ensure it's visible on all screen sizes */
  cursor: pointer; /* Pointer cursor for better UX */
}
  .amica-rose-book-now {
      font-size: 14px; /* Make the button text smaller */
      padding: 8px 16px; /* Adjust padding */
  }

  .amica-rose-view-more {
      display: none; /* Hide the "View More Pictures" button */
  }

  .amica-rose-nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 50%; /* Center the menu horizontally */
    transform: translateX(-57%); /* Centering trick */
    width: 40vw; /* Adjust width to content */
    background-color: #333;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    z-index: 1001; /* Ensure the menu displays over the main image */
  }

  .amica-rose-nav-links.active {
      display: flex;
  }

  .amica-rose-nav-links li {
    /* margin: 0; */
    text-align: center;
    padding: 5px 0;
    /* gap: -100px; */
    }

    .amica-rose-nav-links a:hover {
      background-color: #555; /* Hover background color */
    }
    
  .amica-rose-down-bar {
      flex-direction: column;
      align-items: center;
      font-size: 16px; /* Smaller text for down bar links */
      gap: 5px; /* Reduce space between links */
  }
  
  .amica-rose-main-image-wrap {
    position: relative;
    text-align: center;
    margin: 0px 10px -20px 10px; /* Adjusted margin */
    border-radius: 0px;
    overflow: hidden;
    max-height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amica-rose-fields {
    padding: 20px; /* Reduced padding for inner content spacing */
    margin: 10px auto; /* Centered and reduced vertical margin */
    border-radius: 10px; /* Slightly rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    max-width: 100%; /* Adjust to full width on mobile */
  }

  .amica-rose-section {
    margin-bottom: 20px; /* Reduced space between sections */
    padding: 10px; /* Reduced padding */
  }

  .amica-rose-section h2 {
    font-size: 24px; /* Smaller font size for section titles */
    border-bottom: 1px solid #eec4b4; /* Thinner underline effect */
    padding-bottom: 5px; /* Reduced space between title and content */
    margin-bottom: 10px; /* Reduced space after the title */
  }

  .amica-rose-section p {
    font-size: 16px; /* Smaller font size for paragraphs */
    line-height: 1.5; /* Slightly increased line height for readability */
    padding: 5px 0; /* Reduced padding for spacing */
  }

  .amica-rose-amenities {
    grid-template-columns: 1fr; /* Single column layout for amenities */
    gap: 15px; /* Reduced gap between items */
  }

  .amica-rose-amenity {
    margin-bottom: 8px; /* Reduced margin between amenities */
  }

  .amica-rose-amenity .icon {
    font-size: 18px; /* Adjusted font size for icons */
    margin-right: 8px; /* Reduced space between icon and text */
  }

  .amica-rose-amenity span {
    font-size: 14px; /* Smaller font size for amenity text */
  }

  .amica-rose-carousel img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
