/* Always set the map height explicitly to define the size of the div element that contains the map. */
.google-map-container {
    height: 500px; /* Adjust height as needed */
    width: 100%;
    margin: 20px auto;
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

/* html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
} */

.map-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Align items to the left */
    background-color: white;
    font-family: 'Roboto', sans-serif;
    padding: 40px; /* Added padding for inner content spacing */
    margin: 20px auto; /* Centered and added vertical margin */
    border-radius: 15px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    max-width: 800px; /* Set a max width for the content */
}

.map-container h2 {
    color: #006477; /* Consistent color with theme */
    font-size: 28px; /* Larger font size for section titles */
    border-bottom: 2px solid #eec4b4; /* Underline effect */
    padding-bottom: 10px; /* Space between title and content */
    margin-bottom: 20px; /* Space after the title */
    text-align: left; /* Align titles to the left */
    width: 100%; /* Ensure titles take full width */
}

.map-container p {
    color: #333; /* Consistent color with theme */
    font-size: 18px; /* Font size for paragraph text */
    line-height: 1.5; /* Line height for readability */
    margin-bottom: 15px; /* Space after the paragraphs */
}

.no-underline-link {
    text-decoration: none; /* Remove underline from link */
    color: #006477; /* Match link color to theme */
}

.no-underline-link:hover {
    text-decoration: underline; /* Underline on hover for better UX */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .map-container {
        padding: 20px; /* Reduced padding for inner content spacing */
        margin: 10px auto; /* Centered and reduced vertical margin */
        border-radius: 10px; /* Slightly rounded corners */
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
        max-width: 100%; /* Adjust to full width on mobile */
    }

    .map-container h2 {
        font-size: 24px; /* Smaller font size for section titles */
        border-bottom: 1px solid #eec4b4; /* Thinner underline effect */
        padding-bottom: 5px; /* Reduced space between title and content */
        margin-bottom: 10px; /* Reduced space after the title */
    }

    .map-container p {
        font-size: 16px; /* Smaller font size for paragraphs */
        line-height: 1.4; /* Slightly increased line height for readability */
        margin-bottom: 10px; /* Reduced space after the paragraphs */
    }
}
