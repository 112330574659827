@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* Container for the entire gallery with center alignment */
.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 40px;
  margin: 20px auto;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Allow the container to adjust dynamically */
  box-sizing: border-box; /* Ensure padding is included in width calculation */
  font-family: 'Roboto', sans-serif;
}

.gallery-category {
  margin-bottom: 30px;
  margin-top: 0;
  display: flex;
  flex-direction: column; /* Ensure content stacks properly */
  align-items: center; /* Center align for smaller screens */
}

.gallery-category-container {
  display: flex;
  flex-direction: column; /* Stack categories vertically */
  gap: 20px; /* Add spacing between categories */
  width: 100%; /* Take full width of the container */
  box-sizing: border-box; /* Include padding in width calculation */
  margin: 0 auto; /* Center the container */
}

/* Room sections with flex layout for side-by-side room details and slideshow */
.room-name {
  font-size: 1.8em;
  color: #006477; /* Change text color to match theme */
  text-align: center;
  margin-bottom: 5px;
  font-family: 'Roboto', sans-serif;
}

.room-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0; /* Remove extra margin */
  padding: 0; /* Remove padding */
}

.room-details {
  flex: 1;
  min-width: 0; /* Prevent flex content from stretching too far */
}

.room-slideshow {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  margin: 0; /* Remove extra margin */
  padding: 0; /* Remove extra padding */
}

.room-slideshow img {
  max-width: 100%; /* Ensure images fit the container */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Prevent inline spacing */
}

.room-description {
  font-size: 1.1em;
  margin-bottom: 5px;
  color: #555;
  font-family: 'Roboto', sans-serif;
}

.amenities-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px; /* Reduce gap */
}

.amenity-item {
  display: flex;
  align-items: center;
  font-size: 0.8rem; /* Smaller font size */
  padding: 4px 8px; /* Smaller padding */
  border-radius: 7px;
  background-color: #eec4b467;
  color: #006477;
  font-family: 'Roboto', sans-serif;
}

.amenity-icon {
  margin-right: 6px; /* Reduced margin */
  font-size: 1.2em; /* Smaller icon size */
}

.amenity-name {
  font-size: 0.9rem; /* Smaller font size */
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

.gallery-title {
  font-size: 2.5rem; /* Size of the title */
  color: #006477;    /* Color matching the theme */
  text-align: center; /* Centered title */
  margin-top: 20px;   /* Reduced margin-top to bring it closer to the top */
  margin-bottom: 20px; /* Adjust bottom margin for space after title */
  font-family: 'Roboto', sans-serif;
}

/* Media Queries for Mobile Screens */
@media (max-width: 768px) {
  .gallery-container {
    padding: 20px;
    width: 90%; /* Expand to fill most of the screen */
    max-width: 600px; /* Prevent excessive stretching */
  }

  .gallery-category {
    align-items: flex-start; /* Prevent items from stretching */
    width: 100%; /* Ensure no overflow */
  }

  .gallery-category-container {
    gap: 15px; /* Reduce gap for smaller screens */
    padding: 10px; /* Add padding for better spacing */
    width: 100%; /* Ensure content doesn't overflow */
    box-sizing: border-box;
  }

  .room-section {
    flex-direction: column; /* Stack items vertically */
    gap: 10px; /* Reduce gap between sections */
    margin: 0; /* Remove extra margin */
    padding: 0; /* Remove padding */
    align-items: flex-start; /* Align items properly */
    max-width: 100%; /* Prevent horizontal overflow */
  }

  .room-details, .room-slideshow {
    flex: 0; /* Remove flex growth on smaller screens */
    display: block; /* Stop using flex layout */
    width: 100%; /* Ensure it fits within smaller containers */
  }

  .room-slideshow {
    display: block; /* Stop flex layout */
    margin: 0; /* Remove vertical spacing */
    padding: 0; /* Remove any extra padding */
  }

  .room-slideshow img {
    max-width: 100%; /* Fit the image within the container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove inline behavior */
    margin: 0; /* Center the image without extra spacing */
  }

  .room-description {
    font-size: 1em; /* Reduce font size for descriptions */
    text-align: justify; /* Improve readability */
    word-wrap: break-word; /* Prevent long words from overflowing */
    overflow-wrap: break-word;
  }

  .amenities-list {
    gap: 5px; /* Adjust gap for smaller screens */
    justify-content: flex-start; /* Ensure proper alignment */
  }
}

@media (max-width: 480px) {
  .gallery-container {
    padding: 10px;
    border-radius: 10px; /* Smaller border radius for compact design */
  }

  .gallery-category-container {
    gap: 10px; /* Further reduce gap for very small screens */
    padding: 5px; /* Adjust padding for compact layout */
    max-width: 100%; /* Ensure no horizontal overflow */
  }

  .room-section {
    padding: 0; /* Remove extra padding for smaller screens */
    margin: 0; /* Remove extra margin */
  }

  .room-description {
    font-size: 0.9em; /* Slightly smaller font size */
  }

  .room-slideshow img {
    max-width: 100%; /* Fit the image within its container */
    height: auto; /* Maintain aspect ratio */
    display: block; /* Remove inline spacing */
    margin: 0; /* Remove unnecessary spacing */
  }

  .room-slideshow {
    display: block;
    margin: 0;
    padding: 0;
  }

  .room-name {
    font-size: 1.2em; /* Further reduce font size for very small screens */
  }

  .gallery-title {
    font-size: 1.8rem; /* Adjust title size for very small screens */
  }
}

body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
