.slideshow-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inline-slideshow {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px; /* Set a fixed width for the inline slideshow */
    height: 300px; /* Set a fixed height for the inline slideshow */
    overflow: hidden; /* Hide overflow to keep uniform sizing */
  }
  
.slideshow-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.2s ease-in-out;
}
  
  
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    color: #333;
    background: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 50%;
    user-select: none;
  }
  
  .prev {
    left: -30px; /* Adjust spacing as needed */
  }
  
  .next {
    right: -30px;
  }
  
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
  }
  
  .modal-content-wrapper {
    position: relative;
  }
  
  .modal-content {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain; /* Ensures natural aspect ratio is maintained */
    width: auto;
    height: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px;
    color: #fff;
    cursor: pointer;
  }
  