.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center horizontally */
  background-color: white;
  font-family: 'Roboto', sans-serif;
  padding: 40px;
  margin: 20px auto; /* Center horizontally */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Adjust to fit full width on mobile */
}


.gallery-instructions {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.gallery-container h2 {
  color: #006477;
  font-size: 28px;
  border-bottom: 2px solid #eec4b4;
  padding-bottom: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gallery-container h2 .arrow {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.gallery-container h2 .arrow.up {
  transform: rotate(180deg);
}

.gallery-category-container {
  margin-bottom: 2px;
}

.gallery-category {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.gallery-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin: 10px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  border: 2px solid #006477;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gallery-image.loading {
  opacity: 0.5;
}

.gallery-image.loaded {
  opacity: 1;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.9);
}

.modal-content-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.modal-content {
  max-width: 90%;
  max-height: 90%;
}

.close {
  position: absolute;
  top: 20px;
  right: 35px;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
  cursor: pointer;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: white;
  font-size: 4em;
  user-select: none;
}

.prev {
  left: 10px;
}

.next {
  right: 20px;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 769px) {
  .gallery-container {
    max-width: 800px; /* Maintain fixed width for larger screens */
  }
}

@media (max-width: 768px) {
  .gallery-container {
    padding: 10px;
  }

  .gallery-container h2 {
    font-size: 24px;
    padding-bottom: 8px;
    margin-bottom: 16px;
  }

  .gallery-image {
    width: 100px;
    height: 100px;
    margin: 5px;
  }

  .prev, .next {
    font-size: 3em;
  }
}
