@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@400;700&display=swap');

/* Overlay with white background */
.cookieOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* White with slight transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Centered and larger Cookie Banner */
.cookieBanner {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: #125c68;
  padding: 30px;
  border-radius: 15px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

/* Title for the banner */
.cookieTitle {
  font-family: 'Lobster', cursive;
  font-size: 2em;
  color: #125c68;
  margin-bottom: 15px;
}

/* Justify text in the message */
.cookieMessage {
  font-size: 1.1em;
  text-align: justify;
  margin: 0 0 20px;
}

/* Privacy Policy link */
.policyLink {
  color: #125c68;
  text-decoration: underline;
}

/* Accept button styling */
.acceptButton {
  padding: 15px 30px;
  background-color: #125c68;
  color: #ffffff;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  transition: background 0.3s ease-in-out;
}

.acceptButton:hover {
  background-color: #0e4c57;
}

/* Responsive styling for smaller screens */
@media (max-width: 768px) {
  .cookieBanner {
    padding: 20px;
    max-width: 90%; /* Adjust to fit within smaller screens */
  }

  .cookieTitle {
    font-size: 1.8em; /* Slightly smaller font size for title */
    margin-bottom: 10px;
  }

  .cookieMessage {
    font-size: 1em; /* Reduce font size for better readability */
  }

  .acceptButton {
    padding: 10px 20px; /* Adjust padding for smaller button */
    font-size: 1em; /* Slightly smaller font size */
  }
}

@media (max-width: 480px) {
  .cookieBanner {
    padding: 15px;
    max-width: 100%;
  }

  .cookieTitle {
    font-size: 1.5em;
  }

  .cookieMessage {
    font-size: 0.9em;
  }

  .acceptButton {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
