.contact-container {
  background-color: #006477;
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-content {
  background-color: white;
  margin: 20px auto;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  text-align: center;
}

.contact-email {
  margin-bottom: 20px;
}

.contact-email h2 {
  color: #006477;
  font-size: 28px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.contact-email h2::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #eec4b4;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact-email p {
  color: #333;
  font-size: 18px;
}

.contact-email a {
  color: #006477;
  text-decoration: none;
}

.contact-email a:hover {
  text-decoration: underline;
}

.follow-us {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.follow-us span {
  font-size: 18px;
  color: #333;
}

.rose-insta {
  width: 40px;
  height: 40px;
}

.contact-divider {
  margin: 20px 0;
  border: 0;
  height: 2px;
  background-color: #eec4b4;
}

.contact-form-wrap {
  margin-top: 20px;
}

.contact-form-wrap h2 {
  color: #006477;
  font-size: 28px;
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.contact-form-wrap h2::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #eec4b4;
  position: absolute;
  bottom: 0;
  left: 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form label {
  font-size: 18px;
  color: #333;
  text-align: left;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
}

.contact-form textarea {
  resize: vertical;
  min-height: 100px;
}

.gdpr-text {
  font-size: 14px;
  color: #666;
  margin: 10px 0;
}

.send-button {
  background-color: #eec4b4;
  color: #006477;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
}

.send-button:hover {
  background-color: #d9b3a6;
}

.response-message {
  color: green;
  font-size: 18px;
  margin-bottom: 20px;
  background-color: #eaf7ea;
  padding: 10px;
  border-radius: 5px;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .contact-content {
      padding: 20px;
  }

  .contact-form {
      gap: 10px;
  }

  .contact-form input,
  .contact-form textarea {
      width: 90%; /* Ensures inputs and textareas take full width of their container */
      font-size: 14px;
      padding: 2vw;
      box-sizing: border-box;
  }

  .gdpr-text {
    font-size: 12px; /* Slightly smaller font for compactness */
    margin: 10px auto; /* Center the text */
    padding: 5px; /* Add internal padding */
    width: 90%; /* Ensure it doesn’t exceed the viewport */
    max-width: 100%; /* Prevent overflow */
    box-sizing: border-box; 
  }

  .send-button {
    font-size: 16px; /* Slightly smaller button text */
    padding: 10px 20px; /* Adjust padding */
    width: auto; 
    margin: 0 auto; 
    box-sizing: border-box;
  }
}
